import { get, put, post } from "./lib/http";

function checkAuth(cb) {
    get("/online/auth", cb)
}
function logout(cb) {
    post("/online/auth/logout", {}, cb)
}

function getProjects(cb) {
    get("/rest/projects", cb)
}

function changeClassOfContract(contractId, newClass, cb) {
    put("/attendance/changeClassOfContract" , {"id": contractId, "class": newClass} , cb)
}

function getProjectsForEating(cb) {
    get("/rest/projects?Essensangaben=true", cb)
}

function getAttendance(project, date, cb) {
    get("/attendance/" + project+"?date=" + date, cb)
}
function setAttendance(attendance, cb){
    post("/attendance/update", attendance, cb)
}
function saveMealPlanForWeek(project, year, cw,mealplan,cb){
    put("/mealplan/"+project+"/"+year+cw, mealplan, cb)
}

function getMealPlanForWeek(project, year,cw,cb){
    get("/mealplan/"+project+"/"+year+cw,cb)
}

function exportBookingsForWeek(project, year,cw,cb){
    var req = new XMLHttpRequest()
    req.open("GET", process.env.VUE_APP_BE_URL + "/mealplan/export/"+project+"/"+year+cw,cb)
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        cb(req)
    })
    req.onerror = function (event) {
        cb(req)
    }
    req.send()
}

function importMealplan(plan, cb){
    const formData= new FormData();
    formData.append("plan", plan);
    var req = new XMLHttpRequest()
    req.open("POST", process.env.VUE_APP_BE_URL + "/mealplan/import")
    req.withCredentials = true
    req.addEventListener('load', function (event) {
        try {
            let response = JSON.parse(req.responseText)
            cb(response, req.status)
        } catch (e) {
            cb(false, req.status)
        }
    })
    req.onerror = function (event) {
        cb(false, false)
    }
    req.send(formData)
}

function getChangeRequests(project, cb){
    get("/attendance/requests?project="+project, cb)
}

function acceptRequest(request, cb){
    post("/attendance/requests/"+request.id+"/accept", {comment:request.comment}, cb)
}

function declineRequest(request, cb){
    post("/attendance/requests/"+request.id+"/decline", {comment:request.comment}, cb)
}   

export default {
    checkAuth,
    logout,
    getProjects,
    getAttendance,
    setAttendance,
    getProjectsForEating,
    importMealplan,
    saveMealPlanForWeek,
    getMealPlanForWeek,
    exportBookingsForWeek,
    changeClassOfContract,
    getChangeRequests,
    acceptRequest,
    declineRequest
}

function generateRequestId(message) {
    let hashValue = 0
    for (let i = 0; i < message.length; i++) {
        let charCode = message.charCodeAt(i);
        hashValue = ((hashValue << 5) - hashValue) + charCode;
        hashValue = hashValue & hashValue; // Ensure 32-bit integer limit
    }

    hashValue = hashValue >>> 0; // Convert to unsigned 32-bit integer
    console.log(hashValue.toString(16).padStart(8, '0'));
    return hashValue.toString(16).padStart(8, '0');
}