<template>
  <div class="relative w-full h-full overflow-y-auto">
    <div class="bg-white rounded-lg py-4 pl-4 w-full min-w-fit">
      <span class="font-bold text-xl">Anwesenheitskontrolle</span><br />
      <select v-model="filter.project" @change="getAttendance()">
        <option value="">Bitte Auswählen</option>
        <option
          v-for="project in projects"
          :key="project.id"
          :value="project.id"
        >
          {{ project.name }}
        </option>
      </select>
      <span class="block text-lg font-bold">Tag</span>
      <input
        type="date"
        class="mr-4"
        v-model="filter.date"
        @change="getAttendance()"
      />
      <button v-if="filter.project != ''" @click="getAttendance()">
        Neu Laden
      </button>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 w-full mt-4 min-w-fit">
      <span class="font-bold text-xl">Änderungsanfragen</span><br />
      <span v-if="changeRequests.length == 0" class="font-bold">Keine</span>
      <div v-else>
        Mit einem Klick auf Akzeptieren oder Ablehnen wird eine E-Mail mit dem angegebenen Kommentar an den Antragsteller versendet.
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Tag</th>
              <th>Neue Abholzeit</th>
              <th>Begründung</th>
              <th>Status</th>
              <th>Kommentar (für Aktion)</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="changeRequest in changeRequests" :key="changeRequest">
              <td>{{ changeRequest.name }}</td>
              <td>{{ changeRequest.day }}</td>
              <td>{{ changeRequest.newTime }}</td>
              <td>{{ changeRequest.reason }}</td>
              <td>{{ changeRequest.status }}</td>
              <td><textarea  class="bg-gray-50 p-1" v-model="changeRequest.comment"></textarea></td>
              <td>
                <button
                  class="mr-2"
                  @click="handleRequest('accept', changeRequest)"
                >
                  Akzeptieren
                </button>
                <button
                  class="mr-2"
                  @click="handleRequest('decline', changeRequest)"
                >
                  Ablehnen
                </button>
                <button
                  class="mr-2"
                  @click="handleRequest('mail', changeRequest)"
                >
                  E-Mail
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="bg-white rounded-lg py-4 pl-4 w-full mt-4">
      <span class="font-bold text-xl">Shortcuts</span><br />
      <button class="mr-2 mt-2" @click="checkout15Min()">
        Kinder mit Gehzeit +-15 min abmelden
      </button>
      <button class="mr-2 mt-2" @click="checkVisible(true)">
        Alle angezeigten anmelden
      </button>
      <button class="mr-2 mt-2" @click="checkVisible(false)">
        Alle angezeigten abmelden
      </button>
    </div>
    <div
      class="bg-white rounded-lg py-4 pl-4 mt-4 min-w-fit"
      v-if="contracts.length > 0"
    >
      <table class="w-full">
        <thead>
          <tr>
            <th>Name</th>
            <th>Klasse</th>
            <th>Erwartet</th>
            <th>Geht um</th>
            <th>Wird abgeholt</th>
            <th>Angemeldet</th>
            <th>Abgemeldet</th>
            <th>Entschuldigt</th>
            <th>Kommentar</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                class="border-2 border-gray-200 px-2 py-1"
                v-model="filter.name"
                type="text"
                placeholder="Filter"
                @input="filterContracts()"
              />
            </td>
            <td>
              <input
                class="border-2 border-gray-200 px-2 py-1"
                v-model="filter.class"
                type="text"
                placeholder="Filter"
                @input="filterContracts()"
              />
            </td>
            <td>
              <select
                v-model="filter.attendance.expected"
                @change="filterContracts()"
              >
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select>
            </td>
            <td></td>
            <td>
              <select
                v-model="filter.attendance.started"
                @change="filterContracts()"
              >
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select>
            </td>
            <td>
              <select
                v-model="filter.attendance.ended"
                @change="filterContracts()"
              >
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select>
            </td>
            <td>
              <select
                v-model="filter.attendance.excused"
                @change="filterContracts()"
              >
                <option value="">Filter</option>
                <option value="true">Ja</option>
                <option value="false">Nein</option>
              </select>
            </td>
            <td>
              <input
                class="border-2 border-gray-200 px-2 py-1"
                v-model="filter.attendance.comment"
                type="text"
                placeholder="Filter"
                @input="filterContracts()"
              />
            </td>
          </tr>
          <tr
            v-for="contract in filteredContracts"
            :key="contract.id"
            :class="{
              '!bg-yellow-100': shouldBeHighlighted(contract.attendance),
            }"
          >
            <td class="font-bold">{{ contract.name }}</td>
            <td>
              <input
                type="text"
                v-model="contract.class"
                class="w-full"
                @input="showChangeClassButton = contract.id"
              />
              <div
                v-if="showChangeClassButton == contract.id"
                class="w-full text-center"
              >
                <button @click="changeClass(contract)">Speichern</button>
              </div>
            </td>
            <td class="!text-center">
              <input
                :id="'chkatt_' + contract.id"
                type="checkbox"
                class="inline-block"
                v-model="contract.attendance.expected"
                @change="setAttendance(contract)"
              /><label :for="'chkatt_' + contract.id"></label>
            </td>
            <td>
              <input
                type="time"
                class="w-36 border-b border-gray-300 bg-inherit"
                v-model="contract.attendance.plannedEnd"
                @change="setAttendance(contract)"
              />
            </td>
            <td class="!text-center">
              <span v-if="contract.pickup" class="p-1 bg-red-500 font-bold rounded-lg">Ja</span>
              <span v-else>Nein</span>
            </td>
            <td class="!text-center">
              <input
                :id="'chkattst_' + contract.id"
                type="checkbox"
                class="inline-block"
                v-model="contract.attendance.started"
                @change="setAttendance(contract)"
              /><label :for="'chkattst_' + contract.id"></label>
              <div v-if="contract.attendance.startTime">
                ({{ contract.attendance.startTime }})
              </div>
            </td>
            <td class="!text-center">
              <input
                :id="'chkattend_' + contract.id"
                type="checkbox"
                class="inline-block"
                v-model="contract.attendance.ended"
                @change="setAttendance(contract)"
              /><label :for="'chkattend_' + contract.id"></label>
              <div v-if="contract.attendance.endTime">
                ({{ contract.attendance.endTime }})
              </div>
            </td>
            <td class="!text-center">
              <input
                :id="'chkattex_' + contract.id"
                type="checkbox"
                class="inline-block"
                v-model="contract.attendance.excused"
                @change="setAttendance(contract)"
              /><label :for="'chkattex_' + contract.id"></label>
            </td>
            <td>
              <input
                class="w-full border-b border-gray-300 bg-inherit"
                v-model="contract.attendance.comment"
                @change="setAttendance(contract)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div v-if="filteredContracts.length == 0" class="w-full text-center">
        <span class="font-bold inline-block"
          >Keine Einträge für diesen Filter</span
        ><br />
      </div>
    </div>
    <div
      v-else-if="filter.project"
      class="bg-white rounded-lg py-4 text-center pl-4 w-full mt-4"
    >
      <span class="font-bold text-xl inline-block">Keine Verträge gefunden</span
      ><br />
    </div>
    <div v-else class="bg-white rounded-lg py-4 text-center pl-4 w-full mt-4">
      <span class="font-bold text-xl inline-block">Bitte Projekt auswählen</span
      ><br />
    </div>
  </div>
</template>

<style scoped>
table {
  max-width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
  /* Set the background color for even rows */
}
</style>

<script>
// @ is an alias to /src
import API from "@/API";
export default {
  name: "Attendance",

  data() {
    return {
      showChangeClassButton: 0,
      changeRequests: [],
      projects: [],
      filter: {
        project: "",
        date: "",
        name: "",
        class: "",
        attendance: {
          expected: "",
          started: "",
          ended: "",
          excused: "",
        },
      },
      filteredContracts: [],
      contracts: [],
    };
  },
  methods: {
    checkout15Min() {
      const thresholdInMilliseconds = 15 * 60 * 1000; // 15 minutes in milliseconds
      this.contracts.forEach((contract) => {
        if (contract.attendance.expected && contract.attendance.started) {
          const today = new Date();
          const plannedEndTime = contract.attendance.plannedEnd.split(":");
          const plannedEndDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate(),
            plannedEndTime[0],
            plannedEndTime[1]
          );
          if (
            Math.abs(plannedEndDate.getTime() - today.getTime()) <
            thresholdInMilliseconds
          ) {
            contract.attendance.ended = true;
            this.setAttendance(contract);
          }
        }
      });
    },
    handleRequest(action, request) {
      if (action == "mail") {
        window.open(
          "mailto:" +
            request.mail +
            "?subject=Änderung der Abholzeit auf " +
            request.newTime +
            "&body="
        );
      } else if (action == "accept") {
        API.acceptRequest(request, (r, status) => {
          this.getChangeRequests();
        });
      } else if (action == "decline") {
        API.declineRequest(request, (r, status) => {
          this.getChangeRequests();
        });
      }
    },
    checkVisible(checkin) {
      this.filteredContracts.forEach((contract) => {
        if (checkin) {
          contract.attendance.started = true;
        } else if (contract.attendance.started) {
          contract.attendance.ended = true;
        }
        this.setAttendance(contract);
      });
    },
    getChangeRequests() {
      API.getChangeRequests(this.filter.project, (r, status) => {
        this.changeRequests = r;
      });
    },
    shouldBeHighlighted(attendance) {
      if (attendance.ended || attendance.excused || !attendance.expected) {
        return false;
      }
      if (!attendance.started && !attendance.comment) {
        return true;
      }

      const today = new Date();
      const plannedEndTime = attendance.plannedEnd.split(":");
      const plannedEndDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        plannedEndTime[0],
        plannedEndTime[1]
      );
      console.log(plannedEndDate);
      console.log(today);
      return plannedEndDate < today;
    },
    changeClass(contract) {
      let vm = this;
      API.changeClassOfContract(contract.id, contract.class, (r, status) => {
        this.showChangeClassButton = 0;
      });
    },
    setAttendance(contract) {
      let vm = this;
      API.setAttendance(
        {
          id: contract.id,
          attendance: contract.attendance,
          date: this.filter.date.split("-")[2],
        },
        (r, status) => {
          Object.keys(r).forEach((key) => {
            contract.attendance[key] = r[key];
          });
        }
      );
    },
    getProjects() {
      let vm = this;
      API.getProjects((r, status) => {
        if (status === 200) {
          vm.projects = r;
        }
      });
    },
    filterContracts() {
      let vm = this;
      this.filteredContracts = this.contracts.filter((item) => {
        if (item.name.toLowerCase().indexOf(vm.filter.name.toLowerCase()) < 0) {
          return false;
        }
        if (
          item.class.toLowerCase().indexOf(vm.filter.class.toLowerCase()) < 0
        ) {
          return false;
        }
        if (
          vm.filter.attendance.expected == "true" &&
          !item.attendance.expected
        )
          return false;
        if (vm.filter.attendance.ended == "true" && !item.attendance.ended)
          return false;
        if (vm.filter.attendance.started == "true" && !item.attendance.started)
          return false;
        if (vm.filter.attendance.excused == "true" && !item.attendance.excused)
          return false;
        if (vm.filter.attendance.ended == "true" && !item.attendance.ended)
          return false;
        if (vm.filter.attendance.ended == "false" && item.attendance.ended)
          return false;
        if (vm.filter.attendance.started == "false" && item.attendance.started)
          return false;
        if (vm.filter.attendance.excused == "false" && item.attendance.excused)
          return false;
        if (vm.filter.attendance.ended == "false" && item.attendance.ended)
          return false;
        if (
          vm.filter.attendance.expected == "false" &&
          item.attendance.expected
        )
          return false;
        return true;
      });
    },

    getAttendance() {
      let vm = this;
      this.updateUrl();
      this.getChangeRequests();
      API.getAttendance(vm.filter.project, vm.filter.date, (r, status) => {
        if (status === 200) {
          vm.contracts = r;
          setTimeout(() => {
            vm.filterContracts();
          }, 50);
        }
      });
    },
    updateUrl() {
      this.$router.push({
        query: {
          ...this.$route.query,
          date: this.filter.date,
          project: this.filter.project,
        },
      });
    },
  },
  created() {
    if (this.$route.query.date) this.filter.date = this.$route.query.date;
    else this.filter.date = new Date().toISOString().slice(0, 10);
    if (this.$route.query.project) {
      this.filter.project = this.$route.query.project;
      this.getAttendance();
    }
    this.updateUrl();
    this.getProjects();
  },
};
</script>